import {
  SortDirection,
  useCustomGetJobPhasesLazyQuery,
  GetJobsSortKey,
  useCustomGetJobPhasesScheduleBlocksLazyQuery,
  JobBillingStatus,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { sortDirectionMap } from "constants/sortDirectionMap";
import MDBox from "components/MDBox";
import { Grid, IconButton, InputBase, Paper, Skeleton } from "@mui/material";
import MDSelect from "components/MDSelect/MDSelect";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import useGetAllOrganizationUsers from "hooks/users/useGetAllOrganizationUsers";
import SearchIcon from "@mui/icons-material/Search";
import useGetAllCrews from "hooks/crews/useGetAllCrews";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import MDButton from "components/MDButton";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const jobPhasesSortKeyMap = {
  id: GetJobsSortKey.ID,
  company: GetJobsSortKey.COMPANY,
  start: GetJobsSortKey.START,
  status: GetJobsSortKey.STATUS,
  default: GetJobsSortKey.START,
};

export const jobPhasesInitialSortDirectionKey = "desc";
export const jobPhasesInitialSortDirectionValue = SortDirection.DESC;
export const jobPhasesInitialSortKey = "createdAt";
export const jobPhasesInitialSortKeyValue = GetJobsSortKey.START;

const Search = ({ onQueryChange, onChangeStartDate, startDate }) => {
  const ref = useRef(null);
  return (
    <MDBox position="relative" zIndex={2} mb={1}>
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        flexWrap="wrap"
        gap={2}
      >
        <MDBox
          display={"flex"}
          gap={1}
          sx={{
            ".date-input": {
              border: "none",
              borderBottom: "1px solid #ccced1",
              paddingBlock: "12px",
              fontSize: "14px",
              color: "#344767",
              fontFamily: "Roboto",
              outline: "none !important",
            },
          }}
        >
          <input
            type="date"
            step="1"
            onChange={(e) => onChangeStartDate(e.target.value)}
            defaultValue={dayjs(startDate).format("YYYY-MM-DD")}
            className="date-input"
            ref={ref}
          />
          <MDButton
            color="blue"
            onClick={() => {
              onChangeStartDate("");
              ref.current.value = "";
            }}
          >
            Clear Date
          </MDButton>
        </MDBox>
        <Paper
          component="form"
          sx={{ p: "2px 4px", display: "flex", alignItems: "center", mr: 2 }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "16px" }}
            placeholder="Search Job Phase"
            inputProps={{ "aria-label": "search job phase" }}
            onChange={({ currentTarget }: any) => {
              onQueryChange(currentTarget.value);
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </MDBox>
    </MDBox>
  );
};

const Filters = ({
  onCrewIdChange,
  selectedCrew,
  crews,
  foreManOptions,
  onForemanUserIdChange,
  selectedForeman,
  crewsLoading,
}) => {
  return (
    <MDBox position="relative" zIndex={2}>
      {crewsLoading ? (
        <MDTypography>
          <Skeleton />
        </MDTypography>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDSelect
              fullWidth
              label="Select Foreman"
              name="areaProduct"
              options={[{ label: "View All", value: "" }, ...foreManOptions]}
              onChange={(e) => {
                onForemanUserIdChange(e.target.value);
              }}
              value={selectedForeman}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MDSelect
              fullWidth
              label="Select Crew"
              name="areaProduct"
              options={[{ label: "View All", value: "" }, ...crews]}
              onChange={(e) => {
                onCrewIdChange(e.target.value);
              }}
              value={selectedCrew}
            />
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default function useJobPhasesTableData() {
  let savedFilters = JSON.parse(localStorage.getItem("useJobPhasesTableFilters")) ?? null;
  const { filtering, sorting, offset } = usePaginatedVariables({
    initialSortDirection: jobPhasesInitialSortDirectionKey,
    initialSortKey: jobPhasesInitialSortKey,
    initialCompanyId: savedFilters?.companyId,
    initialBillingStatuses: savedFilters?.billingStatuses,
  });

  useEffect(() => {
    localStorage.setItem("useJobPhasesTableFilters", JSON.stringify(filtering));
  }, [filtering]);

  const {
    loading: companiesLoading,
    data: companiesData,
    error: companiesError,
  } = useGetAllCompanies(true);

  const {
    loading: organizationUsersLoading,
    data: organizationUsersData,
    error: organizationUsersError,
  } = useGetAllOrganizationUsers();

  const { loading: crewsLoading, data: crewsData } = useGetAllCrews();

  const crewsOptions = crewsData?.map((crew) => {
    const crewSelectOptions = { label: crew?.name, value: crew?.id };
    return crewSelectOptions;
  });

  const ids = crewsData?.map(({ foremanUser }) => foremanUser?.id);

  const foremanOptions = crewsData
    ?.filter(({ foremanUser }, index) => !ids.includes(foremanUser?.id, index + 1))
    .map((crew) => {
      return { label: crew?.foremanUser?.name, value: crew?.foremanUser?.id };
    });

  useEffect(() => {
    filtering.setStartsAt(dayjs(Date.now()).format("YYYY-MM-DD"));
  }, []);

  const [getJobPhases, { loading, data: graphqlDataPhases, error: error }] =
    useCustomGetJobPhasesLazyQuery();

  const [
    getJobPhasesScheduleBlocks,
    {
      loading: jobPhasesScheduleBlocksLoading,
      error: jobPhasesScheduleBlocksError,
      data: graphqlScheduleBlocksData,
    },
  ] = useCustomGetJobPhasesScheduleBlocksLazyQuery();

  useEffect(() => {
    if (filtering.startsAt) {
      getJobPhasesScheduleBlocks({
        variables: {
          search: filtering.debouncedSearchTerm,
          sortDirection: sortDirectionMap[sorting.sortDirection],
          sortKey:
            jobPhasesSortKeyMap[sorting.sortKey.toLowerCase()] || jobPhasesInitialSortKeyValue,
          first: offset.first,
          page: offset.page,
          companyId: filtering.companyId ?? null,
          userId: filtering.userID ?? null,
          crewId: filtering.crewID ?? null,
          foremanUserId: filtering.foremanUserId ?? null,
          date: filtering.startsAt
            ? dayjs
                .utc(new Date(filtering.startsAt + " " + "00:00:00"))
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
        fetchPolicy: "no-cache",
      });
    }
    if (!filtering.startsAt) {
      getJobPhases({
        variables: {
          search: filtering.debouncedSearchTerm,
          sortDirection: sortDirectionMap[sorting.sortDirection],
          sortKey:
            jobPhasesSortKeyMap[sorting.sortKey.toLowerCase()] || jobPhasesInitialSortKeyValue,
          first: offset.first,
          page: offset.page,
          companyId: filtering.companyId ?? null,
          billingStatuses: filtering.billingStatuses as JobBillingStatus[],
          userId: filtering.userID ?? null,
          crewId: filtering.crewID ?? null,
          foremanUserId: filtering.foremanUserId ?? null,
          date: filtering.startsAt
            ? dayjs
                .utc(new Date(filtering.startsAt + " " + "00:00:00"))
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
        fetchPolicy: "no-cache",
      });
    }
  }, [
    filtering.startsAt,
    filtering.companyId,
    filtering.userID,
    filtering.crewID,
    filtering.foremanUserId,
    filtering.billingStatuses,
    offset.page,
    offset.first,
    filtering.debouncedSearchTerm,
    sorting.sortDirection,
    sorting.sortKey,
  ]);

  const jobPhasesData: any = filtering.startsAt
    ? graphqlScheduleBlocksData?.getJobPhases.data
    : graphqlDataPhases?.getJobPhases.data;

  const paginationData = filtering.startsAt
    ? graphqlScheduleBlocksData?.getJobPhases.paginatorInfo
    : graphqlDataPhases?.getJobPhases?.paginatorInfo;

  const queryLoading = filtering.startsAt ? jobPhasesScheduleBlocksLoading : loading;

  const queryError = filtering.startsAt ? jobPhasesScheduleBlocksError : error;

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo: paginationData,
  });

  const columns = useMemo(() => {}, []);

  const serachBox = (
    <Search
      onQueryChange={filtering.setSearchTerm}
      onChangeStartDate={filtering.setStartsAt}
      startDate={filtering.startsAt}
    />
  );

  const filterControl = (
    <Filters
      onCrewIdChange={filtering.setCrewID}
      selectedCrew={filtering.crewID}
      onForemanUserIdChange={filtering.setForemanUserId}
      selectedForeman={filtering.foremanUserId}
      crewsLoading={crewsLoading}
      crews={crewsOptions}
      foreManOptions={foremanOptions}
    />
  );

  const renderRowSubComponent = (row: any) => <pre>{JSON.stringify(row, undefined, 2)}</pre>;

  return {
    queryLoading,
    queryError,
    columns,
    filterControl,
    renderRowSubComponent,
    pagination,
    filtering,
    sorting,
    paginatorInfo: pagination.paginatorInfo,
    companiesData,
    companiesLoading,
    companiesError,
    organizationUsersLoading,
    organizationUsersData,
    organizationUsersError,
    jobPhasesData,
    serachBox,
  } as const;
}
